import React from "react"
import Layout from "../Layout"
import styled from "styled-components"
import { fontFamily, size, mq } from "../global.style"
import { Link } from "gatsby"

export default function Home() {
  return (
    <Layout>
      <NotFoundStyled>
        <Thanks>Deze pagina werd niet gevonden.</Thanks>

        <FootNote>
          Sorry voor het ongemak. Terug naar de <Link to="/">homepagina</Link>.
        </FootNote>
      </NotFoundStyled>
    </Layout>
  )
}

const NotFoundStyled = styled.div`
  background-color: #f2f2f2;
  padding: 3.5rem 2rem;

  ${mq.minWidth(size.portrait)} {
    padding: 22rem 2rem;
  }

  ${mq.minWidth(size.landscape)} {
    padding: 6rem 2rem 5.5rem;
  }

  ${mq.minWidth(size.laptop)} {
    padding: 19rem 2rem;
  }

  p {
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansExtraBold")};
    }
  }
`

const Thanks = styled.p`
  ${fontFamily("OpenSansBold")};
  font-size: 1.25rem;
  margin-bottom: 1rem;

  ${mq.minWidth(size.portrait)} {
    font-size: 1.5rem;
  }

  ${mq.minWidth(size.landscape)} {
    font-size: 2rem;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
`

const FootNote = styled.p`
  text-align: center;
  ${fontFamily("OpenSansBoldItalic")};
  line-height: 1.6;
  margin: 0;

  ${mq.minWidth(size.portrait)} {
    font-size: 0.9rem;
  }

  ${mq.minWidth(size.landscape)} {
    font-size: 1.375rem;
  }

  a {
    ${fontFamily("OpenSansExtraBoldItalic")};
    color: #000;
  }
`
